@media screen and (min-width: 1024px) and (max-width: 1300px) {
    :root {
        --lp-central-width: 1000px;
        --lp-rolling-benefit-height: 72px;
    }

    .pruffme_lp_slider_card {
        aspect-ratio: 1.415;
    }

    .pruffme_lp_slider_card.active_card {
        width: 95%;
    }

    .pruffme_lp_product_about {
        height: 500px;
    }

    .pruffme_lp_product_about_card_icon {
        height: 175px;
    }

    .pruffme_lp_cases_block_card_inner {
        gap: 15px;
    }

    .pruffme_lp_cases_block_card_tip {
        font-size: 13px;
    }

    .pruffme_lp_footer_contact, .pruffme_lp_footer_link {
        font-size: 16px;
    }

    .pruffme_lp_footer_right {
        gap: 16px;
    }
}


@media (max-width: 1024px) {
    :root {
        --lp-central-width: 664px;
    }

    .mobile_block {
        display: inherit;
    }

    .desctop_block {
        display: none;
    }

    .pruffme_lp_header_actions_logined_name {
        display: none;
    }

    .pruffme_lp_slider_card.active_card {
        position: absolute;
        transform: scale(0.7);
        width: 1000px;
    }

    .pruffme_lp_slider_card.inactive_card {
        display: none;
    }

    .pruffme_lp_sliders_content {
        min-height: 450px;
    }

    .pruffme_lp_benefits_block {
        flex-direction: column;
    }

    .pruffme_lp_benefits_card {
        width: 100%;
        max-width: var(--lp-central-width);
    }

    .pruffme_lp_product_about {
        flex-direction: column;
        height: auto;
    }

    .pruffme_lp_product_about_main {
        height: 600px;
    }

    .pruffme_lp_product_about_card {
        aspect-ratio: 1/1;
    }

    .pruffme_lp_product_benefits {
        flex-direction: column;
    }

    .pruffme_lp_product_benefits_item {
        gap: 0;
    }

    .pruffme_lp_product_benefits_item:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #DDE1E5;
    }

    .pruffme_lp_product_benefits_item_label {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        margin-bottom: 0;
    }

    .pruffme_lp_product_benefits_item_description {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%
    }

    .pruffme_lp_product_block.product_webinar .pruffme_lp_product_about {
        flex-direction: column-reverse;
    }

    .pruffme_lp_together_wrapper {
        flex-direction: column;
        gap: 32px;
    }

    .pruffme_lp_together_about {
        align-items: center;
    }

    .pruffme_lp_together_about_label,
    .pruffme_lp_together_about_description {
        text-align: center;
    }

    .pruffme_lp_together_animation {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pruffme_lp_together_animation_wrapper {
        width: 550px;
    }

    .pruffme_lp_additional_benefits_grid {
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr;
        gap: 32px;
    }

    .pruffme_lp_additional_benefits_grid_card {
        position: relative;
    }

    .pruffme_lp_additional_benefits_grid_card_icon_box {
        position: absolute;
    }

    .pruffme_lp_additional_benefits_grid_card_description,
    .pruffme_lp_additional_benefits_grid_card_label {
        padding-left: 96px;
    }

    .pruffme_lp_additional_benefits_grid_card_label {
        margin-top: 0;
    }

    .pruffme_lp_mobile_benfits_block {
        display: none;
    }

    .pruffme_lp_cases_block {
        display: none;
    }

    .pruffme_lp_cases_block_mobile {
        margin-top: 100px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pruffme_lp_cases_block_mobile_inner {
        width: var(--lp-central-width);
    }

    .pruffme_lp_cases_block_mobile_navigation {
        margin-top: 32px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .pruffme_lp_cases_block_mobile_cards {
        margin-top: 32px;
    }

    .pruffme_lp_cases_block_mobile_card {
        display: none;
    }

    .pruffme_lp_cases_block_mobile_card.active_card {
        display: block;
    }

    .pruffme_lp_cases_block_mobile_card_description {
        color: #2C2D2E;
        height: 100px;
        overflow: hidden;
        margin-bottom: 12px;

        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_cases_block_mobile_card_benefits_label {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        margin-bottom: 12px;
    }

    .pruffme_lp_cases_block_mobile_card_benefits_box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
    }

    .pruffme_lp_cases_block_mobile_card_benefit_label {
        color: #A8AAB3;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        margin-bottom: 12px;
    }

    .pruffme_lp_cases_block_mobile_card_benefit_picture {
        border-radius: 20px;
        background: #F1F3F5;
        height: 300px;

        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
    }

    .pruffme_lp_cases_block_mobile_card_benefit_tip {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .pruffme_lp_cases_block_mobile_card_benefit_tips {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .pruffme_lp_cases_block_card_section_image {
        width: 80%;
    }

    .pruffme_lp_block_mobile_navigation_item {
        height: 45px;
        border: none;
        border-radius: 23px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        background-color: #F1F3F5;
        color: #2C2D2E;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;

        flex: 1;
    }

    .pruffme_lp_block_mobile_navigation_item.active_button {
        background-color: #041530;
        color: #FFFFFF;
    }

    .pruffme_lp_rolling_benefits_block_inner {
        flex-direction: column;
        position: relative;
    }

    .pruffme_lp_rolling_benefits_block_left_sublabel,
    .pruffme_lp_rolling_benefits_block_left_label {
        text-align: center;
    }

    .pruffme_lp_rolling_benefits_block_left {
        padding: 40px 20px;
    }

    .pruffme_lp_rolling_benefits_block_left_actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 312px;
    }

    .pruffme_lp_rolling_benefits_block_right {
        width: 100%;
        height: 250px;
        position: absolute;
        bottom: 140px;
        border-radius: 24px;
        background: rgba(255, 255, 255, 0.05);
    }

    /* .pruffme_lp_rolling_benefits_item {
        height: var(--lp-rolling-benefit-height)px;;
    }

    .pruffme_lp_rolling_benefits_item_inner {
        height: 47px;   
    } */

    .pruffme_lp_feedback_block_main {
        flex-direction: column;
        width: 100%;
    }

    .pruffme_lp_feedback_block_stats {
        width: 100%;
        height: 440px;
    }

    .pruffme_lp_feedback_block_reviews {
        width: 100%;
    }

    .pruffme_lp_feedback_block_review_card {
        width: var(--lp-central-width);
        height: 540px;
    }

    .pruffme_lp_eduweb_block_card_logo {
        height: 131px;
        width: 300px;
    }

    .pruffme_lp_eduweb_block_main {
        gap: 16px;
    }

    .pruffme_lp_corp_demo_block_inner {
        height: 480px;
    }

    .pruffme_lp_corp_demo_description,
    .pruffme_lp_corp_demo_label {
        width: 450px;
        z-index: 1;
    }

    .pruffme_lp_corp_demo_block_inner {
        justify-content: flex-end;
    }

    .pruffme_lp_corp_demo_action {
        margin-bottom: 40px;
    }


    .pruffme_lp_corp_demo_background_2,
    .pruffme_lp_corp_demo_background_1 {
        display: none;
    }

    .pruffme_lp_corp_demo_background_mobile {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 250px;
    }

    .pruffme_lp_footer_inner {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .pruffme_lp_footer_right {
        width: 100%;
        flex-direction: column;
        border-top: 1px solid rgba(221, 225, 229, 0.4);
        margin-top: 16px;
        padding-top: 16px;
        align-items: flex-start;
    }

    .pruffme_lp_icon_button {
        width: 48px;
        height: 48px;
        border-radius: 12px;
    }

    .pruffme_lp_icon_button>svg {
        width: 25px;
        height: 25px;
    }

    .pruffme_lp_header {
        max-width: 100vw;
        max-width: 100dvw;

        overflow: hidden;
    }

    .pruffme_lp_header_actions_unlogined>.pruffme_lp_demo_button {
        display: none;
    }

    .pruffme_lp_sliders_labels_box {
        gap: 0;
    }

    .pruffme_lp_sliders_action_interactive_button_wrapper {
        display: none;
        width: 100%;
    }

    .pruffme_lp_sliders_action {
        flex-direction: column;
        justify-content: center;
        gap: 8px;
    }

    .pruffme_lp_sliders_action_interactive_box {
        margin-bottom: 48px;

        display: flex;
        justify-content: center;
    }

    .pruffme_lp_sliders_box[data-current-slide="webinar"] .pruffme_lp_sliders_action_interactive_button_wrapper.action_for_webinar,
    .pruffme_lp_sliders_box[data-current-slide="conference"] .pruffme_lp_sliders_action_interactive_button_wrapper.action_for_conference,
    .pruffme_lp_sliders_box[data-current-slide="dashboard"] .pruffme_lp_sliders_action_interactive_button_wrapper.action_for_dashboard {
        display: flex;
    }

    .pruffme_lp_sliders_action_interactive_button {
        height: 56px;
        border-radius: 14px;
        width: 100%;
        gap: 8px;
        font-size: 20px;
    }

    .product_webinar .pruffme_lp_product_about_main_logo {
        top: 30px;
        left: auto;
        right: 40px;
        width: 75%;
    }

    .pruffme_lp_slider_card_dashboard_mobile_figures {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;

        transform: translate(-50%, -50%);
    }

    .pruffme_lp_slider_card_dashboard_mobile_figure {
        display: none;
    }

    .pruffme_lp_slider_card_dashboard_mobile_figures[data-figure="1"] .pruffme_lp_slider_card_dashboard_mobile_figure[data-figure-index="1"] {
        display: block;
        height: 100%;
        width: 100%;

        object-fit: contain;
        object-position: center;
    }

    .pruffme_lp_sliders_label_divider {
        display: none;
    }

    .pruffme_lp_feedback_block_review_card_content_logo {
        height: 30px;
    }

    .pruffme_lp_news_block_content {
        grid-template-columns: 1fr 1fr;
    }

    .pruffme_lp_header_actions_unlogined,
    .pruffme_lp_header_central {
        gap: 0px;
    }
}

@media (max-width: 664px) {
    :root {
        --lp-central-width: 90vw;
    }

    .pruffme_lp_header_central {
        display: none;
    }

    .pruffme_lp_sliders_label {
        font-size: 30px;
    }

    .pruffme_lp_sliders_box {
        margin-top: 40px;
        margin-bottom: 40px;
        width: 100%;
    }

    .pruffme_lp_sliders_labels_box {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .pruffme_lp_sliders_description {
        font-size: 16px;
        margin-top: 16px;
    }

    .pruffme_lp_slider_card.active_card {
        width: calc(var(--lp-central-width) * 2.5);
        transform: scale(0.4)
    }

    .pruffme_lp_sliders_content {
        min-height: 300px;
        margin-top: 16px;
    }

    .pruffme_lp_sliders_action {
        margin-top: 8px;
    }

    .pruffme_lp_action_button,
    .pruffme_lp_sliders_action_interactive_button {
        height: 48px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%
    }

    .pruffme_lp_benefits_block {
        margin-top: 40px;
    }

    .pruffme_lp_benefits_card {
        padding: 12px;
    }

    .pruffme_lp_benefits_icon_wrapper {
        width: 46px;
        height: 46px;
        border-radius: 12px;
    }

    .pruffme_benefit_icon {
        height: 30px;
        width: 30px;
    }

    .pruffme_lp_benefits_info_label {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
    }

    .pruffme_lp_benefits_info_descirption {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_partners_block {
        margin-top: 32px;
    }

    .pruffme_lp_partners_label {
        font-size: 26px;
        font-weight: 700;
        line-height: 125%
    }

    .pruffme_lp_partners_description {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
        margin-top: 4px;
    }

    .pruffme_lp_product_block {
        margin-top: 100px;
    }

    .pruffme_lp_product_tip {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%
    }

    .pruffme_lp_product_label {
        font-size: 26px;
        font-weight: 700;
        margin-top: 8px;
    }

    .pruffme_lp_product_description {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
        margin-top: 8px;
    }

    .pruffme_lp_product_about {
        margin-top: 20px;
    }

    .pruffme_lp_product_about_main {
        height: 290px;
    }

    .product_dashboard .pruffme_lp_product_about_main_logo {
        width: 365px;
        right: 0;
        left: auto;
        top: -80px;
    }

    .pruffme_lp_product_about_main_label {
        font-size: 30px;
        font-weight: 700;
        line-height: 125%;
    }

    .pruffme_lp_product_about_main_description {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_product_about_card {
        overflow: hidden;
        padding-bottom: 16px;
        padding-top: 10px;
    }

    .pruffme_lp_product_about_card>picture {
        flex: 1;
        overflow: hidden;
        position: relative;
    }

    .pruffme_lp_product_about_card_icon {
        width: 100%;
        height: 100%;
    }

    .pruffme_lp_product_about_card>span {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
        padding: 0 10px;
        padding-top: 5px;
    }

    .pruffme_lp_product_benefits_item_label {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_product_benefits_item_description {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%
    }

    .product_webinar .pruffme_lp_product_about_main_label {
        font-size: 72px;
        line-height: 72px;
    }

    .product_webinar .pruffme_lp_product_about_main_logo {
        top: 16px;
        left: auto;
        right: 24px;
        width: 60%;
    }

    .pruffme_lp_product_benefits {
        margin-top: 24px;
    }


    .pruffme_lp_together_animation_wrapper {
        width: 400px;
        max-width: 90vw;
    }

    .pruffme_lp_together_animation_wrapper {
        gap: 10px;
    }

    .pruffme_lp_together_animation_dashboard_sticker {
        height: 80px;
        width: 80px;
    }


    .pruffme_lp_together_animation_dashboard_sticker.bg_sticker {
        top: 10px;
        left: 5px;
        background: #F9CD5B;
    }

    .pruffme_lp_together_animation_dashboard_sticker {
        top: 30px;
        left: 30px;
        font-size: 16px;
    }

    .pruffme_lp_together_animation_webinar_box {
        height: 140px;
    }

    @keyframes togetherAnimBgWidth {
        0% {
            width: 100%;
        }
    
        30% {
            width: 100%;
        }
    
        70% {
            width: 230px;
        }
    
        100% {
            width: 230px;
        }
    }

    @keyframes togetherAnimCursor {
        0% {
            transform: translate(100px, -20px);
        }

        30% {
            transform: translate(100px, -20px);
        }

        70% {
            transform: translate(-49px, -50px);
        }

        100% {
            transform: translate(-49px, -50px);
        }
    }

    .pruffme_lp_together_block {
        margin-top: 80px;
        overflow: hidden;
    }

    .pruffme_lp_together_about {
        gap: 12px;
    }

    .pruffme_lp_together_about_label {
        font-size: 26px;
        font-weight: 700;
        line-height: 125%;
    }

    .pruffme_lp_together_about_description {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_together_wrapper {
        padding: 40px 0;
    }

    .pruffme_lp_additional_benefits_block {
        margin-top: 80px;
    }

    .pruffme_lp_additional_benefits_grid_card_label {
        font-size: 18px;
        font-weight: 600;
        line-height: 125%;
    }

    .pruffme_lp_additional_benefits_grid_card_description {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_additional_benefits_grid_card_icon_box {
        width: 68px;
        height: 68px;
        border-radius: 12px;
    }

    .pruffme_lp_additional_benefits_grid_card_icon {
        width: 40px;
        height: 40px;
    }

    .pruffme_lp_additional_benefits_grid_card_description,
    .pruffme_lp_additional_benefits_grid_card_label {
        padding-left: 80px;
    }

    .pruffme_lp_block_mobile_navigation_item {
        height: 32px;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
    }

    .pruffme_lp_cases_block_mobile_cards {
        margin-top: 16px;
    }

    .pruffme_lp_cases_block_mobile_card_description {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_cases_block_card_section_image_wrapper {
        width: auto;
        height: auto;
        padding: 5px;
    }

    .pruffme_lp_cases_block_card_section_image {
        width: 95%;
    }

    .pruffme_lp_cases_block_mobile_card_benefits_label {
        font-size: 18px;
        font-weight: 600;
        line-height: 125%;
    }

    .pruffme_lp_cases_block_mobile_card_benefit_picture {
        height: 200px;
    }

    .pruffme_lp_rolling_benefits_block_left_label {
        font-size: 30px;
        font-weight: 700;
        line-height: 125%;
    }

    .pruffme_lp_rolling_benefits_block_left_sublabel {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_rolling_benefits_block_right {
        bottom: 100px;
    }

    .pruffme_lp_rolling_benefits_block_left_actions {
        margin-top: 250px;
    }

    .pruffme_lp_news_block,
    .pruffme_lp_feedback_block {
        padding-top: 80px;
    }

    .pruffme_lp_feedback_block_stats {
        height: 320px;
        padding: 16px;
        border-radius: 16px;
    }

    .pruffme_lp_feedback_block_stats_top {
        padding-bottom: 12px;
        margin-bottom: 12px;
    }

    .pruffme_lp_feedback_block_stats_label {
        font-size: 30px;
        font-weight: 700;
        line-height: 125%;
    }

    .pruffme_lp_feedback_block_stats_description {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_feedback_background {
        top: 0px;
        right: -68px;
        height: 146px;
        width: 100%;
    }

    .pruffme_lp_feedback_block_review_card {
        padding: 16px;
        border-radius: 16px;
    }

    .pruffme_lp_feedback_block_review_card_label {
        font-size: 26px;
        font-weight: 700;
        line-height: 125%;
        margin-bottom: 8px;
    }

    .pruffme_lp_feedback_block_review_card_description {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
        margin-bottom: 12px;
    }

    .pruffme_lp_feedback_block_review_card_tip {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_feedback_block_review_card_tips {
        flex-wrap: wrap;
    }

    .pruffme_lp_feedback_block_review_card_content {
        font-size: 16px;
    }

    .pruffme_lp_feedback_block_review_card_content_bottom_right {
        display: none;
    }

    .pruffme_lp_feedback_block_review_card_content_box {
        padding: 16px;
        border-radius: 8px;
    }

    .pruffme_lp_news_block_content {
        margin-top: 20px;
        grid-template-columns: 1fr;
    }

    .pruffme_lp_news_card_label {
        height: auto;
        font-size: 18px;
        font-weight: 600;
        line-height: 125%;
        margin-bottom: 8px;
    }

    .pruffme_lp_news_card_content {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_news_block_inner {
        height: 100%;
    }

    .pruffme_lp_eduweb_block {
        margin-top: 80px;
        padding: 0;
        background-color: transparent;
    }

    .pruffme_lp_eduweb_block_label {
        font-size: 26px;
        font-weight: 700;
        line-height: 125%;
    }

    .pruffme_lp_eduweb_block_description {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .pruffme_lp_eduweb_block_navigation_mobile {
        display: flex;
        gap: 8px;
        width: 100%;
    }

    .pruffme_lp_eduweb_block_card {
        display: none;
    }

    .pruffme_lp_eduweb_block_card.active_card {
        display: flex;
        flex-direction: column;
    }

    .pruffme_lp_eduweb_block_card_label {
        display: none;
    }

    .pruffme_lp_eduweb_block_card_logo {
        width: 100%;
        height: auto;
        padding: 0;
    }

    .pruffme_lp_eduweb_block_card {
        padding: 0;
        margin-top: 12px;
    }

    .pruffme_lp_corp_demo_block {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .pruffme_lp_corp_demo_label {
        width: auto;
        font-size: 26px;
        font-weight: 700;
        line-height: 125%;
    }

    .pruffme_lp_corp_demo_description,
    .pruffme_lp_corp_demo_label {
        width: auto;
        padding: 0 24px;
    }



    .pruffme_lp_interactive_text_block_inner {
        font-size: 26px;

    }

    .pruffme_lp_interactive_text_block {
        padding-top: 60px;
        padding-bottom: 80px;
    }

    .pruffme_lp_interactive_text_changable {
        width: 115px;
        margin-left: 6px;
    }

    .pruffme_lp_interactive_text_action {
        margin-top: 16px;
    }

    .pruffme_lp_interactive_text_action {
        width: 100%;
    }

    .pruffme_lp_interactive_text_action>button {
        width: 100%;
    }

    .pruffme_lp_footer_contact,
    .pruffme_lp_footer_link {
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_header_actions_unlogined_mobile {
        display: block;
        margin-left: 8px;
    }

    .pruffme_lp_mobile_menu {
        height: 100vh;
        height: 100dvh;
        font-family: Inter;
        color: #2C2D2E;
    }

    .pruffme_lp_mobile_menu_inner {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .pruffme_lp_mobile_menu_header {
        flex-shrink: 0;
        display: flex;
        padding: 16px 20px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
    }

    .pruffme_lp_mobile_menu_content {
        flex: 1;
    }

    .pruffme_lp_mobile_menu_actions {
        display: flex;
        flex-direction: column;
        gap: 8px;

        flex-shrink: 0;
        padding: 16px 20px;
        padding-top: 0;
    }

    .pruffme_lp_mobile_menu_actions>button {
        width: 100%;
    }

    .pruffme_lp_mobile_menu_line {
        display: block;
        padding: 16px 20px;
        border-bottom: 1px solid #DDE1E5;

        background-color: transparent;
    }

    .pruffme_lp_mobile_menu_line_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_mobile_menu_line_name {
        color: #2C2D2E;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;

        text-decoration: none;
    }

    .pruffme_lp_mobile_menu_line:visited .pruffme_lp_mobile_menu_line_name {
        text-decoration: none;
        color: #2C2D2E;
    }

    .pruffme_lp_mobile_menu_line_content {
        display: none;
    }

    .pruffme_lp_mobile_menu_line_content.expanded {
        display: flex;
    }

    .pruffme_lp_mobile_menu_line_content.content_languages {
        flex-wrap: wrap;
        gap: 8px;
        padding-top: 16px;
    }

    .pruffme_lp_mobile_menu_line_content.content_products {
        flex-direction: column;
    }

    .pruffme_lp_mobile_menu_line_lang_wrapper {
        display: flex;
        gap: 4px;
    }

    .pruffme_lp_mobile_menu_line_lang_wrapper>span {
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
    }

    .pruffme_lp_mobile_menu_line_lang_item {
        height: 32px;

        display: flex;
        padding: 0 13px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: #F1F3F5;

        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .pruffme_lp_mobile_menu_line_lang_item.active {
        background-color: #041530;
        color: #FFFFFF;
    }

    .pruffme_lp_mobile_menu_product {
        display: flex;
        padding: 12px 0;
        gap: 8px;
        align-self: stretch;
        align-items: center;
    }

    .pruffme_lp_mobile_menu_product:last-child {
        padding-bottom: 0;
    }

    .pruffme_lp_mobile_menu_product:not(:last-child) {
        border-bottom: 1px solid rgba(221, 225, 229, 1);
    }

    .pruffme_lp_mobile_menu_product_logo {
        width: 64px;
        height: 64px;
        object-fit: contain;
        object-position: center;
    }

    .pruffme_lp_mobile_menu_product_info {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .pruffme_lp_mobile_menu_product_name {
        color: #2C2D2E;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_mobile_menu_product_description {
        color: #A8AAB3;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
    }

    .pruffme_lp_corp_demo_background_mobile {
        height: 227px;
    }
    .pruffme_lp_mobile_menu_product_logo,
    .pruffme_lp_header_products_dropdown_card_image {
        width: 95px;
        height: 75px;
    }
}

@media (max-width: 400px) {
    .pruffme_lp_slider_card.active_card {
        width: calc(var(--lp-central-width) * 3.5);
        transform: scale(0.3)
    }

    .product_dashboard .pruffme_lp_product_about_main_logo {
        top: -70px;
        width: 305px;
    }
}