:root {
    --lp-color-blue-dark: #041530;
    --lp-central-width: 1200px;
    --lp-rolling-benefit-height: 72;
}

@font-face {
    font-family: Inter;
    src: url(../assets/fonts/Inter-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url(../assets/fonts/Inter-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url(../assets/fonts/Inter-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url(../assets/fonts/Inter-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url(../assets/fonts/Inter-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url(../assets/fonts/Inter-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

button > * {
    user-select: none;
}

.mobile_block {
    display: none;
}

.pruffme_landing_icon {
    flex-shrink: 0;

    width: 20px;
    height: 20px;
    background-color: transparent;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: transform 0.3s ease;
}

.pruffme_lp_header button {
    font-family: Inter, Rubik, Arial;
}

.pruffme_lp_header_actions_unlogined_mobile {
    display: none;
}

.pruffme_lp_action_animated,
.pruffme_landing_icon.scale-up {
    animation: scaleUpAndDown 1.5s ease-in-out infinite;
}

.pruffme_landing_icon.bounce-right {
    animation: bounceRight 1.5s ease-in-out infinite;
}

.pruffme_lp_action_button>.pruffme_landing_icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

@keyframes scaleUpAndDown {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes bounceRight {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

.pruffme_lp_body {
    line-height: 20px;
    font-family: Inter, Rubik, Arial;
    font-size: 13px;
    font-style: normal;
    background: #FFFFFF;
}

.pruffme_lp_sliders_description {
    user-select: none;
}

.pruffme_lp_header {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--lp-color-blue-dark);
}

.pruffme_lp_header_container {
    position: sticky;
    top: 0;

    height: 62px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--lp-color-blue-dark);

    z-index: 10;
}

.pruffme_lp_header_container_inner {
    max-width: var(--lp-central-width);
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.pruffme_lp_header_button {
    position: relative;

    display: flex;
    height: 38px;
    justify-content: center;
    align-items: center;

    border: none;

    padding: 0 14px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;

    border-radius: 8px;
    color: #FFFFFF;
    background: #216FE6;
    transition: background-color 0.3s ease;
}

.pruffme_lp_header_central_language_box .header-flag-outer-box:hover {
    background-color: transparent;
}

.pruffme_lp_header_button.icon_button {
    width: 38px;
    padding: 0;
}

.pruffme_lp_header_button.link {
    text-decoration: none;
    user-select: none;
    cursor: pointer;
}


.pruffme_lp_header_button.secondary {
    background: rgba(255, 255, 255, 0.10);
}

.pruffme_lp_header_button.shadow {
    background: transparent;
}



.pruffme_lp_header_logo_box {
    height: 32px;
    width: 120px;
    flex-shrink: 0;
}

.pruffme_lp_header_central_language_box {
    width: 80px;
    display: flex;
    justify-content: flex-start;
}

.pruffme_lp_header_central_products_box {
    width: 140px;
    display: flex;
    justify-content: flex-end;
}

.pruffme_lp_header_products_dropdown_card {
    padding: 8px 6px 8px 14px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    width: 505px;
    transition: background-color 0.3s ease-in;
    margin: 0 12px;

    cursor: pointer;
    font-family: Inter, Rubik, Arial;
}


.pruffme_lp_header_products_dropdown_card_logo {
    flex-shrink: 0;

    display: flex;
    justify-content: center;
}

.pruffme_lp_header_logo {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
}

.pruffme_lp_header_actions.show_login_loader .button_loader_content {
    display: flex;
}

.pruffme_lp_header_actions.show_login_loader .button_unlogined_content {
    opacity: 0;
}

.button_loader_content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;

    display: none;

    width: 70px;

    align-items: center;
    justify-content: center;
}

.pruffme_lp_slider_card_dashboard_mobile_figures {
    display: none;
}

.pruffme_lp_header_products_dropdown_card_info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.pruffme_lp_header_products_dropdown_card_name {
    font-size: 16px;
    font-style: normal;
    font-family: Inter;
    font-weight: 500;
    line-height: 125%;
    margin-bottom: 12px;
}

.pruffme_lp_header_products_dropdown_card_benefits,
.pruffme_lp_header_products_dropdown_card_description {
    font-size: 14px;
    color: #A8AAB3;
    font-style: normal;
    font-family: Inter;
    font-weight: 500;
    line-height: 125%;
}

.pruffme_lp_header_products_dropdown_card_benefits {
    padding-top: 6px;
    margin-top: 6px;
    border-top: 1px solid #DDE1E5;
}

.pruffme_lp_header_products_dropdown_card_image {
    width: 195px;
    height: 125px;

    object-fit: contain;
    object-position: center;
}

.pruffme_lp_header_actions {
    flex-shrink: 0;
    position: relative;
}

.pruffme_lp_header_actions_unlogined,
.pruffme_lp_header_central {
    display: flex;
    align-items: center;
    gap: 12px;
}

.pruffme_lp_header_actions_unlogined,
.pruffme_lp_header_actions_logined {
    transition: opacity 0.3s ease-in;
}


.pruffme_lp_header_actions_logined {
    z-index: -1;
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
}

.is-logined .pruffme_lp_header_actions_unlogined {
    opacity: 0;
}

.is-logined .pruffme_lp_header_actions_logined {
    z-index: 1;
    opacity: 1;
    width: 100%;
}

.pruffme_lp_header_actions_logined_box {
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 4px 8px;
    border-radius: 8px;

    background-color: transparent;

    transition: background-color 0.3s ease-in;
}


.pruffme_lp_header_actions_logined_name {
    font-size: 16px;
    font-style: normal;
    font-family: Inter;
    color: #FFFFFF;
    font-weight: 500;
    line-height: 125%;

    max-width: 100px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.pruffme_lp_header_actions_logined_logo {
    width: 40px;
    height: 40px;

    border-radius: 20px;
    background-position: center;
    background-size: contain;

    border: 2px solid rgba(255, 255, 255, 0.80);

    flex-shrink: 0;
}

.pruffme_lp_sliders_box {
    flex: 1;

    margin-top: 80px;
    margin-bottom: 70px;

    max-width: var(--lp-central-width);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.pruffme_lp_sliders_labels_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 20px;
}

.pruffme_lp_sliders_label {
    color: #FFF;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    transition: color 0.3s;
    font-family: Inter;
}

.pruffme_lp_sliders_label.active {
    color: #3D8AFF;
}

.pruffme_lp_sliders_label.label_conference {
    flex-basis: 100%;
}

.pruffme_lp_sliders_action {
    margin-top: 32px;
}

.pruffme_lp_action_button {
    display: flex;
    height: 56px;
    justify-content: center;
    align-items: center;

    border: none;

    padding: 0 24px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;

    border-radius: 14px;
    background: #216FE6;
    transition: background-color 0.3s ease;
}

a.pruffme_lp_action_button {
    text-decoration: none;
}

.pruffme_lp_action_button.secondary {
    background: transparent;
    color: #2C2D2E;
}


.pruffme_lp_action_button.dark {
    background: #041530;
}


.pruffme_lp_icon_button {
    display: flex;
    width: 66px;
    height: 66px;
    justify-content: center;
    align-items: center;

    border: none;

    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.10);

    transition: background-color 0.3s ease;
}

.pruffme_lp_icon_button>svg {
    width: 35px;
    height: 35px;
}

.pruffme_lp_sliders_label_divider {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
}

.pruffme_lp_sliders_labels_dashboard_wrapper {
    position: relative;
}

.pruffme_lp_sliders_labels_dashboard_cursor {
    opacity: 0;
    position: absolute;

    bottom: 100%;
    left: 65%;
    bottom: 85%;

    height: 35px;
    width: 135px;

    transition: opacity 0.2s ease-in;
}

.pruffme_lp_sliders_label.label_dashboard.active~.pruffme_lp_sliders_labels_dashboard_cursor {
    opacity: 1;
}

.pruffme_lp_sliders_label_divider_dot {
    width: 11px;
    height: 11px;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.pruffme_lp_sliders_description {
    text-align: center;

    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 16px;
}

.pruffme_lp_sliders_content {
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 1;
    min-height: 680px;
    margin-top: 45px;

    width: 100%;
    max-width: var(--lp-central-width);
    position: relative;
}


.pruffme_lp_slider_card {
    aspect-ratio: 1.615;

    border-radius: 14px;
    position: relative;
}

.pruffme_lp_slider_card.active_card {
    width: 90%;
    z-index: 1;
}

.pruffme_lp_slider_card.inactive_card {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 80%;
    z-index: 0;
    opacity: 0.7;
    
}

.pruffme_lp_slider_card.inactive_card > div {
    pointer-events: none;
}

.pruffme_lp_slider_card.inactive_card.card_dashboard {
    left: 0;
}

.pruffme_lp_slider_card.inactive_card.card_webinar {
    right: 0;
}

.pruffme_lp_slider_card.active_card.card_dashboard~.pruffme_lp_slider_card.inactive_card.card_webinar {
    right: auto;
    left: 0;
}

.pruffme_lp_slider_card.inactive_card.card_conference {
    right: 0;
}

.pruffme_lp_slider_card_background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 14px;
    background: rgba(255, 255, 255, 0.15);
}

.pruffme_lp_slider_card_inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: 14px;
    border-radius: 12px;
    background: #F1F3F5;
    overflow: hidden;
}

.pruffme_lp_benefits_block {
    margin-top: 70px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.pruffme_lp_benefits_card {
    display: flex;
    gap: 20px;

    padding: 20px;
    border-radius: 24px;
    background: #F1F3F5;
}

.pruffme_lp_benefits_icon_wrapper {
    width: 80px;
    height: 80px;

    border-radius: 20px;
    background-color: var(--lp-color-blue-dark);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.pruffme_benefit_icon {
    height: 45px;
    width: 45px;

    object-fit: contain;
    object-position: center;
}

.pruffme_lp_benefits_info_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pruffme_lp_benefits_info_label {
    color: #2C2D2E;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
}

.pruffme_lp_benefits_info_descirption {
    font-size: 20px;
    color: #2C2D2E;
    font-weight: 500;
    line-height: 125%
}

.pruffme_lp_partners_block {
    margin-top: 70px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.pruffme_lp_partners_label {
    font-size: 40px;
    font-weight: 700;
    line-height: 125%;
}

.pruffme_lp_partners_description {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    margin-top: 10px;
}

.pruffme_lp_partners_infinite_scrolling {
    margin-top: 32px;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 54px;
    /* Высота контейнера */
    position: relative;
}

.pruffme_lp_partners_infinite_scrolling_inner {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    /* will-change: transform;
    animation: partners-left-to-right 20s linear infinite; */
}

@keyframes partners-left-to-right {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.pruffme_partner_icon {
    height: 54px;
    width: auto;
}

.pruffme_lp_partners_infinite_scrolling_icons {
    position: absolute;
    top: 0;
    white-space: nowrap;
    height: 100%;
    will-change: transform;
}

.pruffme_lp_product_block {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 140px;


}

.pruffme_lp_product_block>div {
    max-width: var(--lp-central-width);
    width: 100%;
}

.pruffme_lp_product_tip {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: #A8AAB3;
}

.pruffme_lp_product_label {
    font-size: 40px;
    font-weight: 700;
    line-height: 125%;
    margin-top: 10px;
}

.pruffme_lp_product_description {
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
}

.pruffme_lp_product_tip,
.pruffme_lp_product_label,
.pruffme_lp_product_description {
    text-align: center;
}


.pruffme_lp_product_about {
    display: flex;
    gap: 8px;

    height: 600px;

    margin-top: 38px;
}

.pruffme_lp_product_about_secondary_box,
.pruffme_lp_product_about_main_box {
    flex-basis: 50%;
}

.pruffme_lp_product_about_main_box {
    display: flex;

}

.pruffme_lp_product_about_main {
    border-radius: 24px;
    background-color: #041530;
    padding: 32px;
    position: relative;

    display: flex;
    justify-content: end;
    flex-direction: column;

    width: 100%;
    overflow: hidden;
}

.pruffme_lp_product_about_main_label {
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    color: #FFF;
    line-height: normal;
}

.product_webinar .pruffme_lp_product_about_main_label {
    font-size: 128px;
    line-height: 128px;
}

.product_webinar .pruffme_lp_product_about_main_logo {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;

    object-position: center;
    width: 85%;
}



.pruffme_lp_product_about_main_description {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.pruffme_lp_product_about_secondary_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
}

.pruffme_lp_product_about_card {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    border-radius: 24px;
    background: #F1F3F5;
    padding-bottom: 30px;
}

.pruffme_lp_product_about_card>span {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    max-width: 196px;
}

.pruffme_lp_product_about_card_icon {
    width: 220px;
    height: 220px;

    object-fit: contain;
    object-position: center;
}

.pruffme_lp_product_about_main_logo {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    object-position: center;
}

.pruffme_lp_product_benefits {
    display: flex;
    margin-top: 50px;
}

.pruffme_lp_product_benefits_item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex: 1;
}

.pruffme_lp_product_benefits_delimiter {
    width: 1px;
    margin-left: 32px;
    margin-right: 32px;
    flex-shrink: 0;
    position: relative;
    top: 0;
    bottom: 0;
    background-color: #DDE1E5;
}

.pruffme_lp_product_benefits_item_label {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    margin-bottom: 6px;
}

.pruffme_lp_product_benefits_item_description {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    color: #A8AAB3;
}

.pruffme_lp_product_actions {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 12px;
    align-items: center;
}

.pruffme_lp_together_block {
    margin-top: 140px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: #F1F3F5;
}

.pruffme_lp_together_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 140px;
    padding: 44px 0;

    max-width: var(--lp-central-width);
    width: 100%;
}

.pruffme_lp_together_animation,
.pruffme_lp_together_about {
    flex-basis: 50%;
    flex: 1;

    width: 100%;
}

.pruffme_lp_together_about {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.pruffme_lp_together_about_label {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
}

.pruffme_lp_together_about_description {
    font-size: 20px;
    font-weight: 500;
    line-height: 125%;
}

.pruffme_lp_together_animation_wrapper {
    display: flex;
    gap: 45px;

    position: relative;
    justify-content: flex-end;
    padding: 20px;
    /* background-color: #FFFFFF;
    border-radius: 20px; */
}

.pruffme_lp_together_animation_webinar_box {
    width: 300px;
    height: 200px;
}

.pruffme_lp_together_animation_dashboard_box {
    width: 175px;
    position: relative;
}

.pruffme_lp_together_animation_dashboard_box_inner {
    position: relative;
    width: 100%;
    height: 100%;

    animation: togetherAnimCardPosition 2s infinite alternate ease-in-out;
}

.pruffme_lp_together_animation_dashboard_sticker {
    position: absolute;
    top: 50px;
    left: 40px;
    height: 130px;
    width: 130px;
    box-shadow: 1px 1px 4px 0px rgba(124, 122, 155, 0.15);
    background: #FFDC83;

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
}

.pruffme_lp_together_animation_dashboard_sticker.bg_sticker {
    top: 31px;
    left: 20px;
    background: #F9CD5B;
}

.pruffme_lp_together_animation_background_box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 20px;

    animation: togetherAnimBgWidth 2s infinite alternate ease-in-out;
}

.pruffme_lp_together_animation_cursor {
    position: absolute;
    right: 20px;
    bottom: 20px;

    animation: togetherAnimCursor 2s infinite alternate ease-in-out;
}

.pruffme_lp_together_animation_dashboard_box_background {
    position: absolute;
    top: -20px;
    left: -10px;
    bottom: -20px;
    right: -25px;
    background-color: #FFFFFF;
    border-radius: 20px;

    animation: togetherAnimCardBackground 2s infinite alternate ease-in-out;
}

.pruffme_lp_together_animation_cursor_icon {
    width: 165px;
    height: 50px;

    object-fit: contain;
}

@keyframes togetherAnimBgWidth {
    0% {
        width: 100%;
    }

    30% {
        width: 100%;
    }

    70% {
        width: 340px;
    }

    100% {
        width: 340px;
    }
}

@keyframes togetherAnimCursor {
    0% {
        transform: translate(0, 0);
    }

    30% {
        transform: translate(0, 0);
    }

    70% {
        transform: translate(-100px, -50px);
    }

    100% {
        transform: translate(-100px, -50px);
    }
}

@keyframes togetherAnimCardBackground {
    0% {
        opacity: 0;
        transform: translateX(0);
    }

    30% {
        opacity: 0;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
        transform: translateX(10px);
    }

    100% {
        opacity: 1;
        transform: translateX(10px);
    }
}

@keyframes togetherAnimCardPosition {
    0% {
        transform: translateX(0);
    }

    30% {
        transform: translateX(0);
    }

    70% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(10px);
    }
}

.pruffme_lp_additional_benefits_block {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 140px;
}

.pruffme_lp_additional_benefits_block>div {
    max-width: var(--lp-central-width);
}

.pruffme_lp_additional_benefits_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 17px;

    margin-top: 40px;
}

.pruffme_lp_additional_benefits_grid_card {
    display: flex;
    flex-direction: column;
}

.pruffme_lp_additional_benefits_grid_card_icon_box {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background: #F1F3F5;

    display: flex;
    align-items: center;
    justify-content: center;
}

.pruffme_lp_additional_benefits_grid_card_label {
    margin-top: 16px;
    font-size: 28px;
    font-weight: 600;
    line-height: 125%;
}

.pruffme_lp_additional_benefits_grid_card_description {
    color: #A8AAB3;
    font-size: 20px;
    font-weight: 500;
    line-height: 125%;

    margin-top: 8px;
}

.pruffme_lp_additional_benefits_grid_card_icon {
    width: 54px;
    height: 54px;

    object-fit: contain;
    object-position: center;
}

.pruffme_lp_mobile_benfits_block {
    margin-top: 220px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.pruffme_lp_mobile_benfits_block_inner {
    position: relative;

    max-width: var(--lp-central-width);
    width: 100%;
    background-color: #041530;
    border-radius: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    padding: 60px;
}

.pruffme_lp_mobile_benfits_block_label {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    max-width: 50%;
}

.pruffme_lp_mobile_benfits_block_label>span {
    color: #3484FF;
}

.pruffme_lp_mobile_benfits_block_description {
    font-size: 20px;
    color: #FFF;
    font-weight: 500;
    line-height: 125%;
    max-width: 50%;
}

.pruffme_lp_mobile_benfits_block_bg_icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 70%;
    height: 100%;
}

.pruffme_lp_mobile_benfits_phone {
    position: absolute;
    right: 170px;
}

.pruffme_lp_mobile_benfits_phone_image {
    width: 250px;
    height: 500px;
}


.pruffme_lp_mobile_benfits_phone_scroll_container {
    position: absolute;
    top: 71px;
    left: 15px;
    width: 220px;
    height: 396px;

    overflow: hidden;
}

.pruffme_lp_mobile_benfits_phone_scroll_container_inner {
    display: flex;
    animation: scrollAnimation 4.8s linear infinite;
}

.pruffme_lp_mobile_benfits_phone_scroll_item {
    width: 220px;
    height: 396px;

    object-fit: cover;
    object-position: center;
}

@keyframes scrollAnimation {

    0%,
    25% {
        transform: translateX(0);
    }

    33.33%,
    58.33% {
        transform: translateX(-220px);
    }

    66.66%,
    91.66% {
        transform: translateX(-440px);
    }

    100% {
        transform: translateX(0);
    }
}

.pruffme_lp_cases_block_mobile {
    display: none;
}
.pruffme_lp_cases_block {
    background-color: #F1F3F5;
    padding: 0 0 140px 0;
    margin-top: 160px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.pruffme_lp_cases_block_inner {
    position: relative;

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.pruffme_lp_cases_block_content_control {
    position: relative;
    width: 100%;
    max-width: var(--lp-central-width);
}

.pruffme_lp_cases_block_content {
    flex: 1;
    width: 100%;
    position: relative;
    margin-top: 100px;
}

.pruffme_lp_cases_block_card {
    height: 550px;
    position: absolute;
    width: 100%;
    transition: all 0.5s ease-out;
    overflow: hidden;
    border-radius: 24px;

    padding: 32px;

    background: #FFF;
    box-shadow: 0px -2px 30px 0px rgba(45, 57, 120, 0.10);
}

.pruffme_lp_cases_block_card_inner {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    height: 100%;
}

.pruffme_lp_cases_block_card_right,
.pruffme_lp_cases_block_card_left {
    flex-basis: 50%;
}

.pruffme_lp_cases_block_card_right {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.pruffme_lp_cases_block_card_icon_wrapper {
    width: 100px;
    height: 100px;
    border-radius: 20px;

    background-color: #F1F3F5;

    display: flex;
    align-items: center;
    justify-content: center;
}

.pruffme_lp_cases_block_card_icon {
    width: 40px;
    height: 40px;

    object-fit: contain;
    object-position: center;
}

.pruffme_lp_cases_block_card_label {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
}

.pruffme_lp_cases_block_card_description {
    color: #A8AAB3;
    font-size: 20px;
    font-weight: 500;
    line-height: 125%;
    margin-top: 10px;
}

.pruffme_lp_cases_block_card_tips {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.pruffme_lp_cases_block_card_tip {
    padding: 9px 14px;
    border-radius: 100px;
    background: #F1F3F5;
    font-size: 16px;
    font-weight: 500;
    line-height: 125%;
}

.pruffme_lp_cases_block_sticky_main {
    position: sticky;
    top: 0;
    padding-top: 120px;
    width: 100%;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F1F3F5;
    z-index: 1;
    /* 
    height: 100vh;
    height: 100dvh; */
}

.pruffme_lp_cases_block_content_control_element {
    height: 100vh;
    height: 100dvh;
    width: 100%;
    background-color: #F1F3F5;
    border-radius: 24px;    
    position: relative;
}

.pruffme_lp_cases_block_content_control_card_wrapper.second,
.pruffme_lp_cases_block_content_control_card_wrapper.third,
.pruffme_lp_cases_block_content_control_card_wrapper.first {
    position: sticky;
    top: 290px;
}

.pruffme_lp_cases_block_card_right_label {
    font-size: 20px;
    font-weight: 500;
    line-height: 125%;
    margin-bottom: 20px;
}

.pruffme_lp_cases_block_card_right_main {
    display: flex;
    gap: 12px;
    flex: 1;
}

.pruffme_lp_cases_block_card_fordashboard,
.pruffme_lp_cases_block_card_forweb {
    display: flex;
    flex-direction: column;

    flex: 1;
    flex-basis: 50%;
}

.pruffme_lp_cases_block_card_section_sublabel {
    color: #A8AAB3;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    /* 20px */
    margin-bottom: 12px;
    flex-shrink: 0;
}

.pruffme_lp_cases_block_card_section_image_wrapper {
    display: flex;
    width: 275px;
    height: 303px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 20px;
    background: #F1F3F5;

    flex: 1;

    margin-bottom: 12px;
    position: relative;
}

.pruffme_lp_cases_block_card_section_image {
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.pruffme_lp_cases_block_card_section_benefits {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pruffme_lp_cases_block_card_section_benefits_line {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.pruffme_lp_rolling_benefits_block {
    background-color: var(--lp-color-blue-dark);

    display: flex;
    justify-content: center;

    z-index: 1;
    position: relative;
}

.pruffme_lp_rolling_benefits_block_inner {
    max-width: var(--lp-central-width);
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.pruffme_lp_rolling_benefits_block_left {
    padding: 70px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    padding-right: 100px;

    flex-basis: 50%;
}

.pruffme_lp_rolling_benefits_block_left_label {
    font-size: 40px;
    color: #FFF;
    font-weight: 700;
    line-height: 125%;
}

.pruffme_lp_rolling_benefits_block_left_sublabel {
    font-size: 20px;
    color: #FFF;
    font-weight: 500;
    line-height: 125%;
}

.pruffme_lp_rolling_benefits_block_right {
    height: 100%;
    position: relative;
    flex: 1;
    overflow: hidden;
}

.pruffme_lp_rolling_benefits_rolling_box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    transition: top 0.2s;
}

.pruffme_lp_rolling_benefits_item {
    height: 72px;
    display: flex;
    align-items: center;

    transition: height 0.3s ease-in;
}

.pruffme_lp_rolling_benefits_item_inner {
    border-radius: 100px;
    background: rgba(241, 243, 245, 0.10);

    display: flex;
    align-items: center;
    justify-content: center;

    color: #A8AAB3;
    font-size: 24px;
    font-weight: 500;

    height: 60px;
    padding: 0 18px;

    transition: all 0.3s ease-in;
}

.pruffme_lp_rolling_benefits_item.active_item .pruffme_lp_rolling_benefits_item_inner {
    background: #F1F3F5;
    color: #2C2D2E;
}


.pruffme_lp_news_block,
.pruffme_lp_feedback_block {
    padding-top: 140px;

    display: flex;
    justify-content: center;
    z-index: 1;
    background-color: #FFFFFF;
    position: relative;
}

.pruffme_lp_news_block_inner,
.pruffme_lp_feedback_block_inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: var(--lp-central-width);
}

.pruffme_lp_news_block_inner {
    justify-content: space-between;
}

.pruffme_lp_feedback_block_main {
    margin-top: 40px;
    gap: 8px;
    display: flex;
}

.pruffme_lp_feedback_block_stats {
    position: relative;

    width: 390px;
    height: 540px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding: 32px;

    border-radius: 24px;
    background: #F1F3F5;
    overflow: hidden;
}

.pruffme_lp_feedback_block_stats_label {
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pruffme_lp_feedback_block_stats_description {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pruffme_lp_feedback_block_stats_top {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #A8AAB3;
}

.pruffme_lp_feedback_background {
    position: absolute;
    top: 0;
    right: 0;
    height: 290px;
    width: 100%;
}

.pruffme_lp_feedback_block_reviews {
    flex: 1;
    height: 100%;
    position: relative;

    display: flex;
    overflow: hidden;

    width: calc(var(--lp-central-width) - 390px - 8px);
}

.pruffme_lp_feedback_block_review_card {
    display: flex;
    flex-direction: column;

    padding: 32px 26px 32px 32px;
    border-radius: 24px;
    background: #F1F3F5;

    width: calc(var(--lp-central-width) - 390px - 8px);
    flex-shrink: 0;
}

.pruffme_lp_feedback_block_reviews_inner {
    position: relative;
    display: flex;
}

.pruffme_lp_feedback_block_review_card_index_box {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.pruffme_lp_feedback_block_review_card_index {
    flex: 1;
    border-radius: 100px;
    height: 8px;
    background: #FFF;
    cursor: pointer;
}

.pruffme_lp_feedback_block_review_card_label {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    margin-bottom: 8px;
}

.pruffme_lp_feedback_block_review_card_description {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    color: #A8AAB3;
    margin-bottom: 16px;
}

.pruffme_lp_feedback_block_review_card_tips {
    display: flex;
    gap: 6px;
    margin-bottom: 16px;
}

.pruffme_lp_feedback_block_review_card_tip {
    display: flex;
    padding: 9px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    background: rgba(221, 225, 229, 0.80);
}

.pruffme_lp_feedback_block_review_card_content_box {
    display: flex;
    padding: 20px;
    flex-direction: column;
    flex: 1;
    border-radius: 24px;
    background: #FFF;
}

.pruffme_lp_feedback_block_review_card_content {
    flex: 1;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.pruffme_lp_feedback_block_review_card_content_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pruffme_lp_feedback_block_review_card_content_bottom_left {
    display: flex;
    flex-direction: column;
}

.pruffme_lp_feedback_block_review_card_content_bottom_name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%
}

.pruffme_lp_feedback_block_review_card_content_bottom_position {
    color: #A8AAB3;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.pruffme_lp_feedback_block_review_card_content_logo {
    width: auto;
    height: 44px;

    object-fit: contain;
    object-position: center;
}

.pruffme_lp_feedback_block_reviews_inner {
    display: flex;
    transition: transform 0.4s ease-in-out;
}

/* @keyframes moveHorizontal {
    0% {
        transform: translateX(0);
    }

    4% {
        transform: translateX(0);
    }

    4.8% {
        transform: translateX(calc(-1 * (var(--lp-central-width) - 390px - 8px)));
    }

    24.8% {
        transform: translateX(calc(-1 * (var(--lp-central-width) - 390px - 8px)));
    }

    25.6% {
        transform: translateX(calc(-2 * (var(--lp-central-width) - 390px - 8px)));
    }

    45.6% {
        transform: translateX(calc(-2 * (var(--lp-central-width) - 390px - 8px)));
    }

    46.4% {
        transform: translateX(calc(-3 * (var(--lp-central-width) - 390px - 8px)));
    }

    66.4% {
        transform: translateX(calc(-3 * (var(--lp-central-width) - 390px - 8px)));
    }

    67.2% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
    }
}

.pruffme_lp_feedback_block_reviews_inner {
    animation: moveHorizontal 25s infinite;
    animation-timing-function: linear;
}
*/

.pruffme_lp_feedback_block_review_card:nth-child(4) .pruffme_lp_feedback_block_review_card_index:nth-child(4),
.pruffme_lp_feedback_block_review_card:nth-child(3) .pruffme_lp_feedback_block_review_card_index:nth-child(3),
.pruffme_lp_feedback_block_review_card:nth-child(2) .pruffme_lp_feedback_block_review_card_index:nth-child(2),
.pruffme_lp_feedback_block_review_card:nth-child(1) .pruffme_lp_feedback_block_review_card_index:nth-child(1) {
    background-color: #041530;
} 

.pruffme_lp_news_block_main {
    height: 450px;
    width: 100%;
}

.pruffme_lp_news_block_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    gap: 32px;

    margin-top: 40px;
}

.pruffme_lp_news_block_actions {
    width: 100%;
}

.pruffme_lp_news_actions_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.pruffme_lp_news_actions_button {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;

    border: none;
    padding: 0;
    background-color: transparent;

    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 14px;

    color: #A8AAB3;
}

.pruffme_lp_news_actions_button > svg {
    width:  18px;
    height: 10px;

    object-fit: contain;
    object-position: center;
}

.pruffme_lp_news_actions_button.next > svg {
    transform: rotate(180deg);
}

.pruffme_lp_news_actions_button:disabled {
    opacity: 0.5;
}

.pruffme_lp_news_card {
    border-radius: 20px;
    cursor: pointer;
}

.pruffme_lp_news_card_picture {
    aspect-ratio: 16/9;
    border-radius: 20px;

    object-position: center;
    object-fit: contain;
    overflow: hidden;
    max-width: 100%;
    width: 100%;

    box-shadow: 0px 3px 36px #00000029;
}

.pruffme_lp_news_card_label {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    height: 48px;

    margin-top: 20px;
    margin-bottom: 10px;
}

.pruffme_lp_news_card_content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;

    color: #A8AAB3;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;

}










.pruffme_lp_eduweb_block {
    margin-top: 140px;
    padding: 70px 0;

    display: flex;
    justify-content: center;

    background-color: #F1F3F5;
}

.pruffme_lp_eduweb_block_inner {
    width: var(--lp-central-width);

    display: flex;
    align-items: center;
    flex-direction: column;
}

.pruffme_lp_eduweb_block_label {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    margin-bottom: 12px;
    text-align: center;
}

.pruffme_lp_eduweb_block_label>span {
    color: #216FE6;
}

.pruffme_lp_eduweb_block_description {
    max-width: 550px;

    margin-bottom: 32px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-align: center;
}

.pruffme_lp_eduweb_block_navigation_mobile {
    display: none;
}

.pruffme_lp_eduweb_block_main {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.pruffme_lp_eduweb_block_card {
    border-radius: 20px;
    background: #FFF;
    padding: 20px;
    flex: 1;
}

.pruffme_lp_eduweb_block_card_label {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    text-align: center;
    margin-bottom: 24px;
}

.pruffme_lp_eduweb_block_card_logo {
    height: 180px;
    width: 490px;
    margin-bottom: 24px;

    object-fit: contain;
    object-position: center;
}

.pruffme_lp_eduweb_block_card_actions>button {
    width: 100%;
}

.pruffme_lp_corp_demo_block {
    margin: 140px 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.pruffme_lp_corp_demo_block_inner {
    position: relative;

    height: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: var(--lp-central-width);
    border-radius: 24px;
    background: #041530;

    overflow: hidden;
}

.pruffme_lp_corp_demo_label {
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 50px */

    margin-bottom: 12px;
}

.pruffme_lp_corp_demo_description {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    max-width: 665px;

    margin-bottom: 34px;
}

.pruffme_lp_corp_demo_background_mobile {
    display: none;
}

.pruffme_lp_corp_demo_background_1 {
    position: absolute;
    top: -47px;
    left: -106px;
    transform: rotate(-460deg);
    width: 387px;
    height: 300px;
}

.pruffme_lp_corp_demo_background_2 {
    position: absolute;
    bottom: 7px;
    right: -121px;
    transform: rotate(-270deg);
    width: 387px;
    height: 300px;
}

.pruffme_lp_interactive_text_block {
    padding: 140px 0;
    background-color: #041530;

    display: flex;
    align-items: center;
    justify-content: center;
}

.pruffme_lp_interactive_text_block_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: #FFFFFF;

    max-width: var(--lp-central-width);
}

.pruffme_lp_interactive_text_changable {
    display: inline-block;
    margin-left: 10px;
    width: 280px;
    text-align: left;
}

.pruffme_lp_interactive_text_action {
    margin-top: 32px;
}

.pruffme_lp_footer_block {
    display: flex;
    justify-content: center;

    background-color: #041530;
    padding-bottom: 50px;

    top: -4px;
    position: relative;
    padding-top: 4px;
}

.pruffme_lp_footer_inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    max-width: var(--lp-central-width);
    width: 100%;
}

.pruffme_lp_footer_left {
    display: flex;
    gap: 16px;
}

.pruffme_lp_footer_contact,
.pruffme_lp_footer_link {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    text-decoration: none;
    background: transparent;
    padding: 0;
}



.pruffme_lp_footer_link:visited {
    color: #FFF;
}

.pruffme_lp_footer_contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pruffme_lp_footer_right {
    display: flex;
    gap: 20px;
    align-items: center;
}

@media (hover: hover) {
    .pruffme_lp_header_button.shadow:hover,
    .pruffme_lp_header_button.secondary:hover {
        background: rgba(255, 255, 255, 0.12);
    }

    .pruffme_lp_header_button,
    .pruffme_lp_header_products_dropdown_card,
    .pruffme_lp_sliders_label,
    .pruffme_lp_action_button,
    .pruffme_lp_footer_link {
        cursor: pointer;
    }

    .pruffme_lp_header_button:hover {
        background: #1E64CF;
    }


    .pruffme_lp_header_products_dropdown_card:hover {
        background-color: #F7F7F9;
    }

    .pruffme_lp_header_actions_logined_box:hover {
        background-color: rgba(255, 255, 255, 0.12);
    }

    .pruffme_lp_sliders_label:hover {
        color: rgba(255, 255, 255, 0.90)
    }

    .pruffme_lp_sliders_label.active:hover {
        color: #3D8AFF;
    }


    .pruffme_lp_action_button.secondary:hover {
        background: rgba(0, 0, 0, 0.05);
    }

    .pruffme_lp_action_button:hover {
        background: #1E64CF;
    }


    .pruffme_lp_action_button.dark:hover {
        background: #01060D;
    }

    .pruffme_lp_action_button.secondary:hover {
        background: rgba(0, 0, 0, 0.05);
    }


    .pruffme_lp_feedback_block_reviews_inner:hover {
        animation-play-state: paused;
    }

    .pruffme_lp_footer_link:hover {
        text-decoration: underline;
    }

    .pruffme_lp_news_actions_button:not(:disabled) {
        cursor: pointer;
    }
}


@media screen and (max-height: 850px) {
    .pruffme_lp_cases_block_sticky_main {
        padding-top: 100px;
        padding-bottom: 30px;
    }

    .pruffme_lp_cases_block_content_control_card_wrapper.second,
    .pruffme_lp_cases_block_content_control_card_wrapper.third,
    .pruffme_lp_cases_block_content_control_card_wrapper.first {
        top: 230px;
        transform: scale(0.8);
        /* mne stidno */
    }
}