.pruffme_lp_slider_card_conference_publishers_box_content {
    display: grid;
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
    margin-top: 8px;
}

.pruffme_lp_slider_card_conference_publisher_card_box {
    position: relative;
}

.pruffme_lp_slider_card_conference_publisher_card_box.video-has-reaction .pruffme_lp_slider_card_webinar_conference_card {
    filter: blur(5px);
}

.pruffme_lp_slider_card_webinar_conference_card {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.pruffme_lp_slider_card_confrence_room_box {
    display: flex;
    flex: 1;

    background: #FFF;
    margin: 8px;

    border-radius: 8px;
    box-shadow: 0px 3px 5px 0px rgba(41, 28, 80, 0.05);
}

.pruffme_lp_slider_card_conference_publishers_box_content .alternative_3,
.pruffme_lp_slider_card_conference_publishers_box_content .alternative_2,
.pruffme_lp_slider_card_conference_publishers_box_content .alternative_1 {
    display: none;
}

.pruffme_lp_slider_card_conference_publishers_box_content.show_alternative_1 .alternative_1 {
    display: block;
}

.pruffme_lp_slider_card_conference_publishers_box_content.show_alternative_2 .alternative_2 {
    display: block;
}

.pruffme_lp_slider_card_conference_publishers_box_content.show_alternative_3 .alternative_3 {
    display: block;
}

.pruffme_lp_slider_card_conference_publisher_card_reactions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 8px;

    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(33, 111, 230, 0.50);
}