:root {
    --navbar-button-bg-color: rgba(221, 225, 229, 0.06);
    --navbar-button-hover-bg-color: rgba(221, 225, 229, 0.12);
}


.pruffme_lp_slider_card_webinar_room {
    display: flex;
    flex-direction: column;

    height: 100%;
}

.pruffme_lp_slider_card_webinar_room_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-shrink: 0;
    height: 56px;
    background: #1E1F21;
    padding: 0 12px;
}

.pruffme_lp_slider_card_webinar_room_header_left {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_room_header_counter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0 8px;
    gap: 6px;
    border-radius: 8px;
    background: rgba(47, 239, 123, 0.06);
}

.pruffme_lp_slider_card_webinar_room_header_counter_text {
    color: var(--support-green, #1FC762);
    font-weight: 500;
    width: 56px;
    /* 153.846% */
}

.pruffme_lp_slider_card_webinar_room_header_name {
    color: #FFF;

    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    /* 122.222% */

    text-overflow: ellipsis;
    overflow: hidden;
}

.pruffme_lp_slider_card_webinar_room_header_right {
    flex-shrink: 0;
    display: flex;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_room_content {
    background-color: #DDE1E5;

    display: flex;
    flex: 1;
}

.pruffme_lp_slider_card_webinar_room_content_left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.pruffme_lp_slider_card_webinar_room_content_right {
    flex: 2;
    display: flex;
    position: relative;
}

.pruffme_lp_slider_card_webinar_room_flying_reactions {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 200px;
    z-index: 1;
}

.pruffme_lp_slider_card_webinar_room_content_left .pruffme_lp_slider_card_webinar_room_box {
    max-height: 50%;
}

.pruffme_lp_slider_card_webinar_room_box {
    display: flex;
    flex: 1;

    background: #FFF;
    margin: 8px;

    border-radius: 8px;
    box-shadow: 0px 3px 5px 0px rgba(41, 28, 80, 0.05);
}

.webinar-navbar-button {
    display: flex;

    flex-wrap: nowrap;

    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 0 8px;
    height: 32px;
    border-radius: 8px;

    background: var(--navbar-button-bg-color);

    outline: none;
    border: none;

    transition: background 0.2s ease-in;
    border: 1px solid transparent;
}

.webinar-navbar-button.accent {
    background-color: #216FE6;
    color: #FFFFFF;
}


.webinar-navbar-button:focus-visible {
    border-color: #FFFFFF;
}

.webinar-navbar-button.navbar-button-active {
    background: rgba(61, 150, 255, 0.1);
}


.webinar-navbar-button.navbar-button-active>.webinar-navbar-button-counter,
.webinar-navbar-button.navbar-button-active>.webinar-navbar-button-text {
    color: rgba(61, 150, 255, 1);
}

.webinar-navbar-button-text {
    color: #FFF;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 153.846% */
}

.webinar-navbar-button-counter {
    color: var(--pruffme-text-secondary, #A8AAB3);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 153.846% */
}

.webinar-navbar-button-icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.webinar-navbar-button.header_exit_box {
    background: #ED0A34;
}


.pruffme_lp_slider_card_webinar_publishers_box_inner {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.pruffme_lp_slider_card_webinar_publishers_box_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_publishers_box_header_right,
.pruffme_lp_slider_card_webinar_publishers_box_header_left {
    display: flex;
    align-items: center;
    gap: 8px
}

.pruffme_lp_slider_card_webinar_publishers_box_header_left {
    flex: 1;
}

.webinar-room-action-button {
    display: flex;
    align-items: center;
    justify-content: center;

    background-repeat: no-repeat;
    background-position: center;
    gap: 4px;

    background-color: #F7F7F9;
    color: #2C2D2E;
    border-radius: 5px;
    height: 30px;
}

.webinar-room-action-button.red-style {
    background-color: #FBF0EF;
}

.webinar-room-action-button.green-style {
    background-color: #D7F4DE;
}

.webinar-room-action-button.icon-button {
    width: 30px;
}

.webinar-room-action-button:not(.icon-button) {
    padding: 0 12px;
}


.pruffme_lp_slider_card_webinar_publishers_box_content {
    display: grid;
    flex: 1;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
    margin-top: 8px;
}

.pruffme_lp_webinar_publisher_card {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;

    height: 100%;
    width: 100%;
}

.pruffme_lp_webinar_publisher_card_picture {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.pruffme_lp_webinar_publisher_card.pink {
    background: radial-gradient(70% 70% at 50% 50%, #F46388 0%, #FFCCD9 100%);
}


.pruffme_lp_webinar_publisher_card.blue {
    background: radial-gradient(70% 70% at 50% 50%, #23A7D6 0%, #267FC6 100%);
}

.pruffme_lp_webinar_publisher_card.violet {
    background: radial-gradient(70% 70% at 50% 50%, #F46388 0%, #BF83FF 0.01%, #EDDCFF 100%);
}

.pruffme_lp_webinar_publisher_card.yellow {
    background: radial-gradient(70% 70% at 50% 50%, #D4883A 0%, #FFD8AF 100%);
}

.pruffme_lp_webinar_publisher_card.ocean {
    background: radial-gradient(70% 70% at 50% 50%, #DBEFFE 0%, #75C5FF 100%);
}

.pruffme_lp_webinar_publisher_card_logo {
    height: 70%;
    aspect-ratio: 1/1;

    border-radius: 1000px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.pruffme_lp_webinar_publisher_card_image {
    height: 60%;
    aspect-ratio: 1/1;

    border-radius: 1000px;
    object-fit: cover;
    object-position: center;
}

/* .pruffme_landing_template_avatar_1,
.pruffme_lp_webinar_publisher_card.pink .pruffme_lp_webinar_publisher_card_logo {
    background-image: url(../../../domains/landing/assets/images/webinar_card_logo_pink.png);
    background-image: image-set(
        url(../../../domains/landing/assets/images/webinar_card_logo_pink.webp),
        url(../../../domains/landing/assets/images/webinar_card_logo_pink.png));

}

.pruffme_lp_webinar_publisher_card.blue .pruffme_lp_webinar_publisher_card_logo {
    background-image: url(../../../domains/landing/assets/images/webinar_card_logo_blue.png);
}

.pruffme_landing_template_avatar_2,
.pruffme_lp_webinar_publisher_card.yellow .pruffme_lp_webinar_publisher_card_logo {
    background-image: url(../../../domains/landing/assets/images/webinar_card_logo_yellow.png);
}

.pruffme_landing_template_avatar_3,
.pruffme_lp_webinar_publisher_card.violet .pruffme_lp_webinar_publisher_card_logo {
    background-image: url(../../../domains/landing/assets/images/webinar_card_logo_violet.png);
} */

.pruffme_lp_slider_card_webinar_chat_box_inner {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.pruffme_lp_slider_card_webinar_chat_box_header {
    flex-shrink: 0;

    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F1F3F5;

    gap: 8px;
}

.pruffme_lp_slider_card_webinar_chat_box_header_left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_chat_box_header_right {
    display: flex;
    align-items: center;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_chat_box_messages {
    flex: 1;
    overflow: auto;
    padding: 12px;
}

.pruffme_lp_slider_card_webinar_chat_box_bottom {
    display: flex;
    align-items: center;
    gap: 8px;

    flex-shrink: 0;
    border-top: 1px solid #F1F3F5;

    padding: 12px;
}

.pruffme_lp_slider_card_webinar_chat_logo {
    flex-shrink: 0;
    position: relative;

    width: 32px;
    height: 32px;

    border-radius: 16px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../assets/images/webinar_card_logo_pink.png);
}

.pruffme_lp_slider_card_webinar_chat_bottom_sendline {
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: 8px;

    padding: 0 10px;
    gap: 10px;
    height: 40px;

    background: #F7F7F9;
}

.pruffme_lp_slider_card_webinar_chat_bottom_send_button {
    flex-shrink: 0;
    position: relative;

    width: 32px;
    height: 32px;

    border-radius: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #216FE6;
}

.pruffme_lp_slider_card_webinar_chat_bottom_sendline_input {
    display: block;
    width: auto;
    border: none;
    background-color: transparent;
    height: 32px;
    flex: 1;
}

.pruffme_lp_slider_card_webinar_chat_box_message_line {
    margin-bottom: 20px;
    display: flex;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_chat_box_message_line_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.pruffme_lp_slider_card_webinar_chat_box_message_line_right_top {
    display: flex;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_chat_box_message_line_name {
    font-weight: 500;
}

.pruffme_lp_slider_card_webinar_chat_box_message_line_time {
    color: #B5B5B5;
}

.pruffme_lp_slider_card_webinar_main_box_inner {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.pruffme_lp_slider_card_webinar_main_box_header {
    border-bottom: 1px solid #F1F3F5;
    padding: 12px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.pruffme_lp_slider_card_webinar_main_box_actions,
.pruffme_lp_slider_card_webinar_main_box_left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_main_box_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.pruffme_lp_slider_card_webinar_main_box_content {
    flex: 1;
}

.pruffme_lp_slider_card_webinar_main_box_content_inner {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(112deg, #0273DC -11.06%, #D478FF 100%);
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
}

.pruffme_lp_slider_card_webinar_main_box_bottom {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.pruffme_lp_slider_card_webinar_quiz_results,
.pruffme_lp_slider_card_webinar_quiz {
    display: flex;
    flex-direction: column;

    width: 340px;
    max-width: 90%;
}

.pruffme_lp_slider_card_webinar_quiz_results {
    width: 430px;
}

.pruffme_lp_slider_card_webinar_quiz_results.hidden,
.pruffme_lp_slider_card_webinar_quiz.hidden {
    display: none;
}

.pruffme_lp_slider_card_webinar_quiz_label {
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 16px;
    text-align: center;
}

.pruffme_lp_slider_card_webinar_quiz_box {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 1px 12px 0px rgba(12, 42, 86, 0.06), 0px 1px 12px 0px rgba(12, 42, 86, 0.12);
}

.pruffme_lp_slider_card_webinar_quiz_box_inner {
    display: flex;
    flex-direction: column;
    padding: 12px 16px 16px 16px;
}

.pruffme_lp_slider_card_webinar_quiz_box_name {
    font-weight: 500;
}

.pruffme_lp_slider_card_webinar_quiz_box_description {
    color: #A8AAB3;
    margin-bottom: 14px;
}

.pruffme_lp_slider_card_webinar_quiz_box_logo {
    border-radius: 12px;
    height: 84px;

    background-position: center;
    background-image: url(../assets/images/webinar_card_quiz-bg.png);
    background-size: contain;
    background-repeat: no-repeat;

    margin-bottom: 14px;
}


.pruffme_lp_checkbox {
    position: relative;

    width: 20px;
    height: 20px;

    flex-shrink: 0;

    overflow: hidden;

}

.pruffme_lp_checkbox>label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    background-color: #FFFFFF;
    border: 1px solid #DDE1E5;
    border-radius: 5px;

    transition: border 0.1s ease-in, background-color 0.1s ease-in;
}



.pruffme_lp_checkbox>input:checked+label {
    background-color: #387EE9;
}

.pruffme_lp_checkbox .pruffme_landing_icon {
    width: 11px;
    height: 11px;
}



.pruffme_lp_checkbox>input:focus-visible+label {
    border-color: black;
}

.pruffme_lp_checkbox>input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    width: 0px;
    height: 0px;

    margin: 0;
    padding: 0;
}

.pruffme_lp_slider_card_webinar_quiz_box_options {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.pruffme_lp_slider_card_webinar_quiz_box_option {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    border-radius: 8px;
    background-color: #F7F7F9;
    padding: 0 12px;
}

.pruffme_lp_slider_card_webinar_quiz_results_box_name {
    text-align: center;
    font-weight: 600;
    line-height: 20px
}

.pruffme_lp_slider_card_webinar_quiz_results_box_description {
    color: #A8AAB3;
    text-align: center;
}

.pruffme_lp_slider_card_webinar_quiz_results_content {
    border-radius: 12px;
    background: #F1F3F5;
    height: 200px;
    margin-top: 20px;

    display: flex;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_left {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    padding-right: 22px;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_result {
    padding: 8px 0;

    display: flex;
    align-items: center;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_result:not(:last-child) {
    border-bottom: 1px solid #DDE1E5;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_result_icon {
    width: 17px;
    height: 17px;
    border-radius: 4px;

    flex-shrink: 0;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_result:nth-child(1) .pruffme_lp_slider_card_webinar_quiz_results_content_result_icon {
    background: #257CFF;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_result:nth-child(2) .pruffme_lp_slider_card_webinar_quiz_results_content_result_icon {
    background: #63DAEB;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_result:nth-child(3) .pruffme_lp_slider_card_webinar_quiz_results_content_result_icon {
    background: #B9A8FF;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_result_text {
    font-weight: 500;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    flex: 1;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_result_score {
    font-weight: 500;
}

.pruffme_lp_slider_card_webinar_quiz_results_content_graph {
    width: 160px;
    height: 160px;
}

.pruffme_lp_slider_card_webinar_action {
    position: relative;
}

.pruffme_lp_slider_card_webinar_action_dropdown {
    display: none;
    position: absolute;
    top: calc(100%);

    padding-top: 10px;
    min-width: 100px;
    z-index: 1;
}

.pruffme_lp_slider_card_webinar_action_dropdown_inner {
    background-color: #f9f9f9;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    border-radius: 12px;


    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
}

.pruffme_lp_slider_card_webinar_action_dropdown_item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    height: 68px;
    width: 68px;

    border: none;
    outline: none;
    border-radius: 8px;

    cursor: pointer;
}

.pruffme_lp_slider_card_webinar_action_dropdown_item_icon {
    width: 32px;
    height: 32px;
}


.pruffme_lp_webinar_publisher_card {
    position: relative;
}

.pruffme_lp_webinar_publisher_card_reactions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: none;
    justify-content: center;
    align-items: center;
}

.pruffme_lp_webinar_publisher_card_reaction_icon {
    display: none;

    height: 70%;
    aspect-ratio: 1 / 1;
}

.pruffme_lp_webinar_publisher_card_reaction_icon.handup {
    display: block;
}

.pruffme_lp_slider_card_conference_publisher_card_box.video-has-reaction .pruffme_lp_slider_card_conference_publisher_card_reactions,
.pruffme_lp_webinar_publisher_card.video-has-reaction .pruffme_lp_webinar_publisher_card_reactions {
    display: flex;
}

.pruffme_lp_webinar_publisher_card.video-has-reaction .pruffme_lp_webinar_publisher_card {
    -webkit-filter: blur(5px) brightness(0.5);
    -moz-filter: blur(5px) brightness(0.5);
    -ms-filter: blur(5px) brightness(0.5);
    -o-filter: blur(5px) brightness(0.5);
    filter: blur(5px) brightness(0.5);
}

.pruffme_lp_webinar_publisher_card.reaction-boom .boom,
.pruffme_lp_webinar_publisher_card.reaction-clap .clap,
.pruffme_lp_webinar_publisher_card.reaction-fire .fire,
.pruffme_lp_webinar_publisher_card.reaction-heart .heart,
.pruffme_lp_webinar_publisher_card.reaction-smile .smile,
.pruffme_lp_webinar_publisher_card.reaction-thumbsup .thumbsup {
    display: block;
}

.flying-reaction {
    width: 30px;
    height: 30px;
    opacity: 1;
    position: absolute;
    bottom: 0;
    display: none;


    background-size: contain;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.colOne {
    color: #fce473;
}

.colTwo {
    color: #f68b39;
}

.colThree {
    color: #ed6c63;
}

.colFour {
    color: #847bb9;
}

.colFive {
    color: #97cd76;
}

.colSix {
    color: #35b1d1;
}

@keyframes flowOne {
    0% {
        opacity: 0;
        bottom: 0;
        left: 14%
    }

    40% {
        opacity: .8;
    }

    50% {
        opacity: 1;
        left: 0;
    }

    60% {
        opacity: .2;
    }

    80% {
        bottom: 80%
    }

    100% {
        opacity: 0;
        bottom: 100%;
        left: 18%
    }
}

@keyframes flowTwo {
    0% {
        opacity: 0;
        bottom: 0;
        left: 0;
    }

    40% {
        opacity: .8;
    }

    50% {
        opacity: 1;
        left: 11%
    }

    60% {
        opacity: .2;
    }

    80% {
        bottom: 60%
    }

    100% {
        opacity: 0;
        bottom: 80%;
        left: 0;
    }
}

@keyframes flowThree {
    0% {
        opacity: 0;
        bottom: 0;
        left: 0;
    }

    40% {
        opacity: .8;
    }

    50% {
        opacity: 1;
        left: 30%
    }

    60% {
        opacity: .2;
    }

    80% {
        bottom: 70%
    }

    100% {
        opacity: 0;
        bottom: 90%;
        left: 0;
    }
}

.pruffme_lp_slider_card_publisher_overlay.show_overlay {
    display: flex;
}

.pruffme_lp_slider_card_publisher_overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px;

    flex-direction: column;
    justify-content: space-between;

    display: none;
}

.pruffme_lp_slider_card_publisher_overlay_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pruffme_lp_slider_card_publisher_overlay_top_left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.pruffme_lp_slider_card_publisher_overlay_speaker_name {
    height: 32px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.50);
    width: fit-content;

    font-size: 16px;
    line-height: 125%;
}

.pruffme_lp_slider_card_publisher_overlay_button {
    height: 32px;
    width: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.50);
}



@media (hover: hover) {

    .webinar-navbar-button.accent:hover {
        background-color: #0055D7;
    }

    .webinar-navbar-button:hover {
        background: var(--navbar-button-hover-bg-color);
    }

    .webinar-navbar-button:disabled:hover {
        background: var(--navbar-button-bg-color);
    }

    .webinar-navbar-button.action_button {
        cursor: pointer;
    }

    .pruffme_lp_slider_card_publisher_overlay_button:hover {
        background: rgba(0, 0, 0, 0.70);
    }


    .webinar-navbar-button.navbar-button-active:hover {
        background: rgba(61, 150, 255, 0.15);
    }

    .pruffme_lp_slider_card_conference_publisher_card_box:not(.active-videobox):hover .pruffme_lp_slider_card_publisher_overlay,
    .pruffme_lp_webinar_publisher_card:not(.active-videobox):hover .pruffme_lp_slider_card_publisher_overlay {
        display: flex;
    }


    .webinar-navbar-button.header_exit_box:hover {
        background: #C8082C;
    }

    .webinar-navbar-button.header_exit_box:disabled:hover {
        background: #ED0A34;
    }

    .pruffme_lp_checkbox>label:hover {
        background-color: #F0F1F4;
    }

    .pruffme_lp_checkbox>input:checked+label:hover {
        background-color: #216FE6;
        border-color: #216FE6;
    }


    .pruffme_lp_slider_card_webinar_action_dropdown_item:hover {
        background-color: #F0F1F4;
    }


    .webinar-room-action-button:hover {
        background-color: #F0F1F4;
    }

    .pruffme_lp_slider_card_webinar_quiz_box_option {
        cursor: pointer;
    }

    .pruffme_lp_slider_card_webinar_action:hover .pruffme_lp_slider_card_webinar_action_dropdown {
        display: block;
    }
}