body.has_landing_news .pruffme_landing_header_news_container {
    max-height: 62px;
}

.pruffme_landing_header_news_container {
    max-height: 0;
    height: 62px;
    overflow: hidden;
    width: 100%;
    flex-shrink: 0;

    transition: max-height 0.15s ease-out;
}

.pruffme_landing_header_news_container_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 16px;
    background-color: var(--pruffme_landing_header_news_color, #456EFF);
}

.pruffme_landing_header_news_container_button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 32px;

    margin: 0;
    padding: 0 18px;

    font-family: Inter, Rubik, Arial;
    font-size: 16px;
    font-weight: 500;
    color: var(--pruffme_landing_header_news_text_color, #FFFFFF);
    text-decoration: none;

    border: none;
    outline: none;
    border-radius: 8px;
    background-color: var(--pruffme_landing_header_news_button_color, #FFFFFF10);

    cursor: pointer;
}

.pruffme_landing_header_news_container_button:hover {
    text-decoration: none;
    color: var(--pruffme_landing_header_news_text_color, #FFFFFF);
    background-color: var(--pruffme_landing_header_news_button_hover_color, #FFFFFF20);
}

.pruffme_landing_header_news_container_button:visited {
    text-decoration: none;
    color: var(--pruffme_landing_header_news_text_color, #FFFFFF);
    background-color: var(--pruffme_landing_header_news_button_color, #FFFFFF20);
}

.pruffme_landing_header_news_container_text {
    font-family: Inter, Rubik, Arial;
    font-size: 16px;
    font-weight: 500;
    color: var(--pruffme_landing_header_news_text_color, #FFFFFF);
}